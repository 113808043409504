import {
  labelChats,
  labelCoaches,
  labelCommunity,
  labelConference,
  labelDashboard,
  labelGoals,
  labelMyCoaches,
  labelProfile,
  labelSettings,
  labelToDos,
  labelTutorials,
} from '@src/common/i18n/i18nMainMenu';
import {
  labelCreateQuestionnaire,
  labelQuestionnaire,
} from '@src/common/i18n/i18nQuestionnaire';

import {
  labelCertificationTabPathName,
  labelContacts,
  labelFinancesPathName,
  labelJoinedProgramsPathName,
  labelLibraryPathName,
  labelMySquadsPathName,
  labelPaymentsTabPathName,
  labelServicesPathName,
  labelSessionNotesPathName,
  labelToolboxPathName,
  labelUserProfileTabPathName,
} from '../common/i18n/i18nCommon';

export const PATH_TYPE = {
  about: '/about',
  accessDenied: '/access-denied',
  actionAuth: '/action/auth',
  chats: '/chats',
  coaches: '/coaches',
  community: '/community',
  conference: '/conferences',
  connectStripe: '/connect-stripe',
  contacts: '/contacts',
  content: '/content',
  createQuestionnaire: '/create-questionnaire',
  foodDiary: '/food-diary',
  foodDiaryMeal: '/food-diary/meal',
  foodDiaryOnboarding: '/food-diary-onboarding',
  foodDiaryTrackers: '/food-diary/edit-trackers',
  goals: '/goals',
  googleConnect: '/connect-google',
  login: '/login',
  mailchimpUnsubscribe: '/unsubscribe-mess',
  myCalendar: '/my-calendar', // v2
  myCoaches: '/my-coaches',
  myFinances: '/my-finances',
  myJoinedPrograms: '/my-joined-programs',
  myLibrary: '/my-library',
  myServices: '/my-services',
  mySessionNotes: '/my-notes',
  myToolbox: '/my-toolbox',
  notifications: '/notifications',
  onboarding: '/onboarding',
  practice: '/practice',
  profile: '/profile',
  program: '/program',
  questionnaire: '/questionnaire',
  questions: '/questions',
  settings: '/settings',
  singleEventPage: '/event-page', // v2
  standardTemplateDetail: '/standard-template-detail',
  status: '/status',
  subscriptionPayments: '/profile/user-payments',
  support: '/support-chats',
  toDos: '/to-dos', // v2
  trackers: `/trackers`,
  tutorials: '/tutorials',
  unsubscribe: '/unsubscribe-mess',
  userCertificationTab: '/certification',
  userPaymentsTab: '/user-payments',
  userProfile: '/user-profile',
  userProfilePage: '/profile-page', // v2
  userProfileTab: '/user-profile-tab',
  userQuestionnaire: '/user-questionnaire',
  widget: '/widget',
  yourSpace: '/your-space',
};

const PATH_TITLE = {
  [PATH_TYPE.chats]: labelChats,
  [PATH_TYPE.coaches]: labelCoaches,
  [PATH_TYPE.community]: labelCommunity,
  [PATH_TYPE.conference]: labelConference,
  [PATH_TYPE.contacts]: labelContacts,
  [PATH_TYPE.createQuestionnaire]: labelCreateQuestionnaire,
  [PATH_TYPE.goals]: labelGoals,
  [PATH_TYPE.myCoaches]: labelMyCoaches,
  [PATH_TYPE.myFinances]: labelFinancesPathName,
  [PATH_TYPE.myJoinedPrograms]: labelJoinedProgramsPathName,
  [PATH_TYPE.myLibrary]: labelLibraryPathName,
  [PATH_TYPE.myServices]: labelServicesPathName,
  [PATH_TYPE.mySessionNotes]: labelSessionNotesPathName,
  [PATH_TYPE.myToolbox]: labelToolboxPathName,
  [PATH_TYPE.practice]: labelMySquadsPathName,
  [PATH_TYPE.profile]: labelProfile,
  [PATH_TYPE.questionnaire]: labelQuestionnaire,
  [PATH_TYPE.settings]: labelSettings,
  [PATH_TYPE.toDos]: labelToDos,
  [PATH_TYPE.tutorials]: labelTutorials,
  [PATH_TYPE.userCertificationTab]: labelCertificationTabPathName,
  [PATH_TYPE.userPaymentsTab]: labelPaymentsTabPathName,
  [PATH_TYPE.userProfileTab]: labelUserProfileTabPathName,
  [PATH_TYPE.yourSpace]: labelDashboard,
};

export const getPathTitle = (path: string) => {
  const pathTitle = PATH_TITLE[path];

  return pathTitle ? pathTitle() : unSlugify(path);
};

const unSlugify = (name: string) => {
  name = name ? name[0]!.toUpperCase() + name.slice(1) : '';

  return name.split('-').join(' ');
};
