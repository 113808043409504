import '@yourcoach/shared/utils/datetime/setupDayjs';

// eslint-disable-next-line no-restricted-imports
import React, {
  type FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import 'react-contexify/ReactContexify.css';
import {createPortal} from 'react-dom';
import {Helmet} from 'react-helmet';
import {useHistory} from 'react-router-dom';
import {ToastProvider as OriginalToastProvider} from 'react-toast-notifications';

import Bugsnag from '@bugsnag/js';
import NiceModal from '@ebay/nice-modal-react';
import {
  API_VERSION,
  B2B_API_VERSION,
  IS_WIDGET_ON_MOBILE_KEY,
  TITLE,
} from '@root/config';
import {reaction, when} from 'mobx';
import {createGlobalStyle} from 'styled-components';

import type {ApiRpcRequest} from '@yourcoach/shared/api/utils/apiRequest';
import type {StopConferenceConfig} from '@yourcoach/shared/modules/conference';
import type {RateConferenceConfig} from '@yourcoach/shared/modules/conference-rating';

import {authStore} from '@yourcoach/shared/api/auth';
import {currentUserStore} from '@yourcoach/shared/api/user';
import {apiRequest} from '@yourcoach/shared/api/utils/apiRequest';
import {alert, AlertContainer} from '@yourcoach/shared/components/alert';
import {LightboxContainer} from '@yourcoach/shared/components/lightbox';
import {ModalService} from '@yourcoach/shared/components/Modal/stores/ModalService/Modal.service';
import {UserWaitlistStore} from '@yourcoach/shared/core/stores/UserWaitlist.store';
import {LocalDbService} from '@yourcoach/shared/core/system/localDB/LocalDb.service';
import {InjectionProvider} from '@yourcoach/shared/IoC';
import {createRootContainerApp} from '@yourcoach/shared/IoC/containerFactories/createRootContainer.app';
import {LiveSessionStrategy} from '@yourcoach/shared/modules/book-conference';
import {runBookConferenceFlow} from '@yourcoach/shared/modules/book-conference/runBookConferenceFlow';
import {conferenceStopHandler} from '@yourcoach/shared/modules/conference';
import {runRateConferenceFlow} from '@yourcoach/shared/modules/conference-rating';
import {privacyPolicyUpdateService} from '@yourcoach/shared/services/privacy-policy-update';
import {syncTimeZoneService} from '@yourcoach/shared/services/sync-time-zone';
import {ThemeContext} from '@yourcoach/shared/styles/ThemeProvider';
import {getColor} from '@yourcoach/shared/styles/utils';
import {appStateManager} from '@yourcoach/shared/utils/appState';
import {coreOrB2bEnvSwitcher} from '@yourcoach/shared/utils/coreOrB2b/CoreOrB2bEnvSwitcher';
import {createFile} from '@yourcoach/shared/utils/entity/file';
import {envManager} from '@yourcoach/shared/utils/env';
import {
  emitter,
  WS_RECEIVE_MESSAGE_EVENT,
} from '@yourcoach/shared/utils/eventEmitter';
import {logger} from '@yourcoach/shared/utils/logger';
import {reloadApp} from '@yourcoach/shared/utils/reloadApp';
import {storage} from '@yourcoach/shared/utils/storage';

import {useSetReturnPath} from '@src/common/useUserMethods';
import Toast from '@src/components/Toast/Toast';
import WS from '@src/components/WS/WS';
import AppContext from '@src/context/App';
import useURLQueryParam from '@src/hooks/useURLQueryParam';
import {init as i18nInit, t} from '@src/i18n';
import {Routes} from '@src/routes';
import '@src/styles/index.css';
import {PageLoading} from '@src/v2/components/PageLoading';
import {B2CDemoStore, b2cDemoStore} from '@src/v2/modules/b2c-demo';
import {DocumentViewerNiceModal} from '@src/v2/modules/document-viewer-modal';
import {RatingConferenceModal} from '@src/v2/modules/rating-conference-modal';
import {NavigationServiceProvider} from '@src/v2/services/navigation/NavigationServiceProvider';
import {Logger} from '@src/v2/utils/logger';

const ToastProvider = __SSR__
  ? ({children}) => children
  : OriginalToastProvider;

const GlobalStyles = createGlobalStyle`
  #nprogress {
    .bar {
      background: ${getColor('global1')}
    }

    .spinner {
      display: none;
    }
  }
`;

const init = async (isB2b = false) => {
  const apiEndpoint = isB2b
    ? process.env.B2B_API_ENDPOINT!
    : process.env.API_ENDPOINT!;

  envManager.__setEnv({
    apiEndpoint: process.env.API_ENDPOINT!,
    apiVersion: API_VERSION,
    // @ts-expect-error: string vs union
    appEnv: process.env.APP_ENV,
    b2bApiEndpoint: process.env.B2B_API_ENDPOINT!,
    b2bApiVersion: B2B_API_VERSION,
    communityProgramId: process.env.COMMUNITY_PROGRAM_ID!,
    googleMapsKey: process.env.GOOGLE_MAPS_KEY!,
    isB2b,
    opentokKey: isB2b ? process.env.B2B_OPENTOK_KEY! : process.env.OPENTOK_KEY!,
    stripeKey: process.env.STRIPE_KEY!,
  });

  apiRequest.configure({
    apiEndpoint,
    apiVersion: isB2b ? B2B_API_VERSION : API_VERSION,
    onRequestDidEnd: (payload: ApiRpcRequest | ApiRpcRequest[]) => {
      logger.logApiRequest(payload);
    },
  });

  logger.__setPlatformLogger(new Logger());

  if (
    // see mobile_app/app/screens/Widget.tsx
    typeof __MOBILE_WIDGET_INIT_DATA !== 'undefined' &&
    (await storage.getItem(IS_WIDGET_ON_MOBILE_KEY)) !== 'true'
  ) {
    const {session, session_key, user, user_key} = __MOBILE_WIDGET_INIT_DATA;

    // TODO: Should we expose the env switcher key outside?
    storage.setItem('cb2b_env', 'b2b');
    storage.setItem(session_key, session);
    storage.setItem(user_key, user);
    storage.setItem(IS_WIDGET_ON_MOBILE_KEY, 'true');

    reloadApp();
  }
};

const App: FC = () => {
  const {
    stores: {cardStore, categoryStore, membershipStore, rewardProfileStore},
  } = useContext(AppContext);

  const rootContainer = useMemo(() => createRootContainerApp(), []);

  const {setAccentColor, theme, themes} = useContext(ThemeContext);

  const setRetPath = useSetReturnPath();

  const history = useHistory();

  const [i18nIsReady, setI18nIsReady] = useState(__SSR__);
  const [isEnvReady, setIsEnvReady] = useState(__SSR__);
  const [isIndexDbReady, setIsIndexDbReady] = useState(false);

  // demo mode
  const demoPartnerId = useURLQueryParam(B2CDemoStore.ACTIVATION_PARAM);
  const demoColor = useURLQueryParam(B2CDemoStore.COLOR_PARAM);

  if (demoPartnerId && process.env.APP_ENV !== 'prod') {
    b2cDemoStore.init(demoPartnerId);
    b2cDemoStore.setColor(demoColor);
  }

  const ErrorBoundary = useMemo(() => {
    if (__SSR__ || !isEnvReady) {
      return ({children}) => children;
    }

    return Bugsnag.getPlugin('react')!.createErrorBoundary(React);
  }, [isEnvReady]);

  useEffect(
    () =>
      when(
        () => coreOrB2bEnvSwitcher.isReady,
        () => {
          init(coreOrB2bEnvSwitcher.isB2b).then(() => {
            if (demoPartnerId) {
              if (b2cDemoStore.isActive.isTrue) {
                setAccentColor({
                  dark: b2cDemoStore.color!,
                  light: b2cDemoStore.color!,
                });
              }
            } else if (coreOrB2bEnvSwitcher.isB2b) {
              setAccentColor({
                dark: themes.dark.color.v3.static.b2b,
                light: themes.light.color.v3.static.b2b,
              });
            } else {
              setAccentColor({
                dark: themes.dark.color.v3.background.primary,
                light: themes.light.color.v3.background.primary,
              });
            }

            setIsEnvReady(true);
          });
        },
      ),
    [demoPartnerId, setAccentColor, themes],
  );

  useEffect(() => {
    setRetPath();

    i18nInit().finally(() => {
      setI18nIsReady(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // just initialize the store
    rootContainer.get(UserWaitlistStore);
  }, [rootContainer]);

  useEffect(() => {
    const selector = '#root';

    const setupModal = () => {
      rootContainer.get(ModalService).init({
        ariaHideSelector: selector,
        blurSelector: '#allContent',
        mountElementSelector: selector,
      });
    };

    const initLocalDb = () => {
      rootContainer
        .get(LocalDbService)
        .init()
        .then(() => {
          setIsIndexDbReady(true);
        });
    };

    initLocalDb();

    setupModal();
  }, [rootContainer]);

  const runChecks = useCallback(async () => {
    if (!(await currentUserStore.userExists())) {
      return;
    }

    await privacyPolicyUpdateService({
      openDocument: async ({showAlert, title, url}) => {
        alert.hide();

        await NiceModal.show(DocumentViewerNiceModal, {
          document: createFile({
            categories: ['document'],
            id: title,
            name: title,
            size: 1 * 1024,
            url,
          }),
          title,
        });

        showAlert();
      },
    });
    await syncTimeZoneService({
      onEditScheduleClick: () => {
        const pathname = '/my-calendar';
        const state = {isScheduleOpen: true};

        if (history.location.pathname === pathname) {
          history.replace({state});
        } else {
          history.push(pathname, state);
        }
      },
    });
  }, [history]);

  const onAppBecameActive = useCallback(async () => {
    if (currentUserStore.user) {
      currentUserStore.fetch();
      rewardProfileStore.refresh();
      cardStore.fetchCards();
      membershipStore.fetchMemberships();
    }

    categoryStore.fetchCategories();

    runChecks();
  }, [
    cardStore,
    categoryStore,
    membershipStore,
    rewardProfileStore,
    runChecks,
  ]);

  useEffect(
    () =>
      appStateManager.onStateChange(state => {
        if (state === 'active') {
          onAppBecameActive();
        }
      }),
    [onAppBecameActive],
  );

  useEffect(() => {
    const disposers = [
      reaction(
        () => currentUserStore.user && currentUserStore.user._id,
        () => {
          logger.setUser(currentUserStore.user);

          if (currentUserStore.user) {
            cardStore.fetchCards();
            membershipStore.fetchMemberships();

            runChecks();
          }
        },
      ),
      reaction(
        () => currentUserStore.user?.subtenant?.partner_id,
        partnerId => {
          if (partnerId) {
            logger.setUserProperty('partner_id', partnerId);
          }
        },
      ),
      reaction(
        () => currentUserStore.user && currentUserStore.user.is_blocked,
        async () => {
          if (currentUserStore.user && currentUserStore.user.is_blocked) {
            /**
             * This will never be called. The backend will delete all sessions after a user is blocked.
             * So the user will be logged out because of the expired session error.
             */
            alert.clearQueue(true);

            currentUserStore.clear();
            authStore.logout().catch(() => {});
            authStore._deleteSession();

            await alert.show({
              title: t('inAppNotification.blocked_account'),
            });

            if (envManager.isB2b) {
              coreOrB2bEnvSwitcher.toggleEnv();
            }
          }
        },
      ),
    ];

    categoryStore.fetchCategories();

    return () => {
      disposers.forEach(dispose => dispose());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWsMessage = useCallback(
    message => {
      if (
        [
          'coach_blocked',
          'coach_unblocked',
          'points_added',
          'tier_downgraded',
          'tier_upgraded',
        ].includes(message.event.type)
      ) {
        rewardProfileStore.refresh();
      }

      if (
        [
          'coach_became_restricted',
          'coach_verified',
          'google_oauth_access_became_invalid',
        ].includes(message.event.type)
      ) {
        currentUserStore.fetch();
      }
    },
    [rewardProfileStore],
  );

  useEffect(() => {
    emitter.on(WS_RECEIVE_MESSAGE_EVENT, handleWsMessage);

    return () => {
      emitter.off(WS_RECEIVE_MESSAGE_EVENT, handleWsMessage);
    };
  }, [handleWsMessage]);

  const showRateConferenceModal = useCallback(
    (config: RateConferenceConfig) => {
      NiceModal.show(RatingConferenceModal, {
        id: config.id,
        isB2b: config.isB2b,
        role: config.role,
        type: config.type,
      });
    },
    [],
  );

  const onRateConference = useCallback(
    async (config: StopConferenceConfig) => {
      runRateConferenceFlow(config, {
        runCommonFlow: showRateConferenceModal,
      });
    },
    [showRateConferenceModal],
  );

  const onBookNextConference = useCallback(
    async ({courseId}: StopConferenceConfig) => {
      const strategy = new LiveSessionStrategy(courseId);

      runBookConferenceFlow({
        conferenceDuration: strategy.conferenceDuration,
        courseId,
        place: 'book_next_popup',
        strategy,
      });
    },
    [],
  );

  useEffect(() => {
    conferenceStopHandler.setRateConferenceHandler(onRateConference);
    conferenceStopHandler.setBookNextConferenceHandler(onBookNextConference);
  }, [onRateConference, onBookNextConference]);

  return (
    <>
      <Helmet defaultTitle={TITLE} titleTemplate={`%s | ${TITLE}`}>
        <meta content="width=1220" name="viewport" />
      </Helmet>
      <InjectionProvider container={rootContainer}>
        <NavigationServiceProvider>
          <NiceModal.Provider>
            <ErrorBoundary>
              <ToastProvider autoDismissTimeout={10000} components={{Toast}}>
                <div id="allContent">
                  {i18nIsReady && isEnvReady && isIndexDbReady ? (
                    <Routes />
                  ) : (
                    <PageLoading />
                  )}
                </div>

                <WS />
                <GlobalStyles theme={theme} />
              </ToastProvider>
              <LightboxContainer />
              <Alert />
            </ErrorBoundary>
          </NiceModal.Provider>
        </NavigationServiceProvider>
      </InjectionProvider>
    </>
  );
};

const Alert = () => {
  return createPortal(<AlertContainer />, document.body);
};

export default memo(App);
