import {lazy, type LazyExoticComponent, Suspense} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';

import {PageLoading} from '@src/v2/components/PageLoading';

import {PathBuilderService} from '../v2/services/PathBuilderService';
import PrivateRoute from './PrivateRoute';
import {PATH_TYPE} from './utils';

const ChatsPage = lazy(
  () => import('@src/pages/Chats' /* webpackChunkName: "chats" */),
);

const SupportChatPage = lazy(
  () =>
    import(
      '@src/pages/SupportChat/SupportChat' /* webpackChunkName: "support_chat" */
    ),
);

const NewChatsPage = lazy(
  () => import('@src/pages/NewChat/NewChat' /* webpackChunkName: "new_chat" */),
);

const CoachesPage = lazy(
  () => import('@src/pages/Coaches' /* webpackChunkName: "coaches" */),
);

const ConferencePage = lazy(
  () => import('@src/v2/pages/Conference' /* webpackChunkName: "conference" */),
);

const ConnectStripePage = lazy(
  () =>
    import('@src/pages/ConnectStripe' /* webpackChunkName: "connect_stripe" */),
);

const GoogleConnectPage = lazy(() =>
  import(
    '@src/v2/pages/GoogleConnect' /* webpackChunkName: "google_connect" */
  ).then(module => ({default: module.GoogleConnectPage})),
);

const CoursePage = lazy(() =>
  import('@src/v2/pages/Course' /* webpackChunkName: "course" */).then(
    module => ({default: module.CoursePage}),
  ),
);

const CourseGoalsPage = lazy(() =>
  import(
    '@src/v2/pages/CourseGoals' /* webpackChunkName: "course-goals" */
  ).then(module => ({default: module.CourseGoalsPage})),
);

const CRUProgramPage = lazy(
  () => import('@src/pages/CRUProgram' /* webpackChunkName: "cru_program" */),
);

const DashboardPage = lazy(
  () => import('@src/pages/Dashboard' /* webpackChunkName: "dashboard" */),
);

const FoodDiaryOnboardingPage = lazy(
  () =>
    import(
      '@src/v2/pages/FoodDiaryOnboardingPage' /* webpackChunkName: "food_diary_onboarding" */
    ),
);

const FoodDiaryPageLayout = lazy(
  () =>
    import(
      '@src/v2/pages/FoodDiaryPageLayout' /* webpackChunkName: "food_diary_layout" */
    ),
);

const GoalsPage = lazy(
  () => import('@src/pages/Goals' /* webpackChunkName: "goals" */),
);

const LoginPage = lazy(
  () => import('@src/pages/Login' /* webpackChunkName: "login" */),
);

const MailchimpUnsubscribePage = lazy(
  () =>
    import(
      '@src/pages/MailchimpUnsubscribe' /* webpackChunkName: "mailchimp_unsubscribe" */
    ),
);

const OnboardingPage = lazy(
  () => import('@src/pages/Onboarding' /* webpackChunkName: "onboarding" */),
);

const PracticePublicPage = lazy(
  () =>
    import(
      '@src/pages/PracticePublic' /* webpackChunkName: "practice_public" */
    ),
);

const PracticePage = lazy(
  () => import('@src/pages/Practice' /* webpackChunkName: "practice" */),
);

const ProfilePage = lazy(
  () => import('@src/pages/Profile' /* webpackChunkName: "profile" */),
);

const ProgramPublicPage = lazy(
  () =>
    import('@src/pages/ProgramPublic' /* webpackChunkName: "program_public" */),
);

const ProgramPage = lazy(
  () => import('@src/pages/Program' /* webpackChunkName: "program" */),
);

const QuestionnairePage = lazy(
  () =>
    import('@src/pages/Questionnaire' /* webpackChunkName: "questionnaire" */),
);

const QuestionnaireTemplatesPage = lazy(
  () =>
    import(
      '@src/pages/QuestionnaireTemplatesPage' /* webpackChunkName: "questionnaire_templates" */
    ),
);

const TutorialsPage = lazy(
  () => import('@src/pages/Tutorials' /* webpackChunkName: "tutorials" */),
);

const ContentListPage = lazy(() =>
  import(
    '@src/v2/pages/ContentList' /* webpackChunkName: "content_list" */
  ).then(module => ({default: module.ContentListPage})),
);

const ContentPage = lazy(() =>
  import('@src/v2/pages/Content' /* webpackChunkName: "content" */).then(
    module => ({
      default: module.ContentPage,
    }),
  ),
);

const TutorialsPublicPage = lazy(
  () =>
    import(
      '@src/pages/TutorialsPublic' /* webpackChunkName: "tutorials-public" */
    ),
);

const UnknownPage = lazy(
  () => import('@src/pages/Unknown' /* webpackChunkName: "unknown" */),
);

const UserQuestionnairePage = lazy(
  () =>
    import(
      '@src/pages/UserQuestionnaire' /* webpackChunkName: "user_questionnaire" */
    ),
);

const ToDosPage = lazy(() =>
  import('@src/v2/pages/ToDos' /* webpackChunkName: "todos" */).then(
    module => ({default: module.ToDosPage}),
  ),
);

const MyCalendarPage = lazy(() =>
  import('@src/v2/pages/MyCalendar' /* webpackChunkName: "my_calendar" */).then(
    module => ({default: module.MyCalendarPage}),
  ),
);

const SingleEventPage = lazy(() =>
  import(
    '@src/v2/pages/SingleEvent' /* webpackChunkName: "single_event" */
  ).then(module => ({default: module.SingleEventPage})),
);

const FirstUserRegister = lazy(() =>
  import('@src/modules' /* webpackChunkName: "first_user_register" */).then(
    module => ({default: module.FirstUserRegister}),
  ),
);

const TrackersPage = lazy(() =>
  import('@src/v2/pages/TrackersPage' /* webpackChunkName: "trackers" */).then(
    module => ({default: module.TrackersPage}),
  ),
);

const AccessDeniedPage = lazy(() =>
  import(
    '@src/v2/pages/AccessDenied' /* webpackChunkName: "access-denied" */
  ).then(module => ({default: module.AccessDeniedPage})),
);

const WidgetPage = lazy(() =>
  import('@src/v2/pages/Widget' /* webpackChunkName: "widget" */).then(
    module => ({default: module.WidgetPage}),
  ),
);

export const AssessmentDetailsPage = lazy(() =>
  import(
    '@src/v2/pages/AssessmentDetails' /* webpackChunkName: "assessment-details" */
  ).then(module => ({default: module.AssessmentDetails})),
);

const UserProfilePage = lazy(() =>
  import(
    '../v2/pages/UserProfile/UserProfile.page' /* webpackChunkName: "user_profile_page" */
  ).then(module => ({default: module.UserProfilePage})),
);

export type RouteConfig = {
  component: LazyExoticComponent<any>;
  exact?: boolean;
  forCoach?: boolean;
  path: string | undefined;
  publicComponent?: LazyExoticComponent<any>;
};

const privateRoutes: RouteConfig[] = [
  {
    component: AssessmentDetailsPage,
    path: PathBuilderService.PATH_TEMPLATE_TO_ASSESSMENTS,
  },
  {
    component: TrackersPage,
    path: PathBuilderService.PATH_TEMPLATE_TO_TRACKERS,
  },
  {
    component: DashboardPage,
    path: PATH_TYPE.yourSpace,
  },
  {
    component: FirstUserRegister,
    exact: true,
    path: PATH_TYPE.userProfile,
  },
  {component: ProfilePage, path: PATH_TYPE.profile},
  {component: CoachesPage, exact: true, path: PATH_TYPE.community},
  {component: CoachesPage, exact: true, path: PATH_TYPE.myCoaches},
  {component: ChatsPage, path: PATH_TYPE.chats},
  {component: ToDosPage, path: PATH_TYPE.toDos},
  {component: GoalsPage, path: PATH_TYPE.goals},
  {component: OnboardingPage, path: PATH_TYPE.onboarding},
  {
    component: ConferencePage,
    exact: true,
    path: `${PATH_TYPE.conference}/:conferenceId(conference:[0-9a-f]{24})`,
  },
  {
    component: ConnectStripePage,
    exact: true,
    path: PATH_TYPE.connectStripe,
  },
  {
    component: GoogleConnectPage,
    exact: true,
    path: PATH_TYPE.googleConnect,
  },
  {
    component: CoursePage,
    exact: true,
    path: PathBuilderService.PATH_TEMPLATE_TO_COURSE,
  },
  {
    component: CourseGoalsPage,
    exact: true,
    path: PathBuilderService.PATH_TEMPLATE_TO_COURSE_GOALS,
  },
  {
    component: QuestionnaireTemplatesPage,
    exact: true,
    forCoach: true,
    path: PATH_TYPE.createQuestionnaire,
  },
  {
    component: QuestionnairePage,
    forCoach: true,
    path: PATH_TYPE.questionnaire,
  },

  {
    component: CRUProgramPage,
    exact: true,
    forCoach: true,
    path: PATH_TYPE.program,
  },
  {
    component: CRUProgramPage,
    exact: true,
    forCoach: true,
    path: `${PATH_TYPE.program}/:programId(program:[0-9a-f]{24})`,
  },
  {
    component: CRUProgramPage,
    exact: true,
    forCoach: true,
    path: `${PATH_TYPE.program}/clone/:clonedProgramId(program:[0-9a-f]{24})`,
  },
  {component: SingleEventPage, path: `${PATH_TYPE.singleEventPage}/:id`},
  {component: MyCalendarPage, path: PATH_TYPE.myCalendar},
  {
    component: PracticePage,
    exact: true,
    path: PathBuilderService.PATH_TEMPLATE_TO_PRACTICE,
    publicComponent: PracticePublicPage,
  },
  {
    component: ProgramPage,
    exact: true,
    path: PathBuilderService.PATH_TEMPLATE_TO_PROGRAM,
    publicComponent: ProgramPublicPage,
  },
  {
    component: TutorialsPage,
    exact: true,
    path: PATH_TYPE.tutorials,
    publicComponent: TutorialsPublicPage,
  },
  {
    component: ContentListPage,
    exact: true,
    path: PATH_TYPE.content,
  },
  {
    component: ContentPage,
    path: `${PATH_TYPE.content}/:articleId(article:[0-9a-f]{24})`,
  },
  {
    component: UserQuestionnairePage,
    path: PATH_TYPE.userQuestionnaire,
    publicComponent: UserQuestionnairePage,
  },
  {
    component: FoodDiaryPageLayout,
    path: PATH_TYPE.foodDiary,
  },
  {
    component: FoodDiaryOnboardingPage,
    exact: true,
    path: PATH_TYPE.foodDiaryOnboarding,
  },
  {component: NewChatsPage, path: '/new-chat'},
  {component: SupportChatPage, path: PATH_TYPE.support},
  {
    component: UserProfilePage,
    path: `${PATH_TYPE.userProfilePage}/:userId(user:[0-9a-f]{24})`,
  },
];

const publicRoutes: RouteConfig[] = [
  {component: LoginPage, exact: true, path: PATH_TYPE.login},
  {component: LoginPage, exact: true, path: PATH_TYPE.actionAuth},
  {
    component: MailchimpUnsubscribePage,
    exact: true,
    path: PATH_TYPE.mailchimpUnsubscribe,
  },
  {
    component: AccessDeniedPage,
    exact: true,
    path: PATH_TYPE.accessDenied,
  },
  {
    component: WidgetPage,
    path: PATH_TYPE.widget,
  },
  {component: UnknownPage, path: undefined},
];

export const Routes = () => {
  const location = useLocation();

  return (
    <>
      <Switch>
        {privateRoutes.map(
          ({component, exact, forCoach, path, publicComponent}, index) => {
            const Component = component;

            return (
              <PrivateRoute
                exact={exact}
                forCoach={forCoach}
                key={index}
                path={path}
                publicComponent={publicComponent}
                render={p => (
                  <Suspense fallback={<PageLoading />}>
                    {/* @ts-expect-error: has no properties in common with type 'IntrinsicAttributes' */}
                    <Component {...p} />
                  </Suspense>
                )}
              />
            );
          },
        )}
        <Redirect
          exact
          from={'/'}
          to={{
            pathname: PATH_TYPE.yourSpace,
            state: location.state,
          }}
        />
        {publicRoutes.map(({component, exact, path}, index) => {
          const Component = component;

          return (
            <Route
              exact={exact}
              key={index}
              path={path}
              render={p => (
                <Suspense fallback={<PageLoading />}>
                  {/* @ts-expect-error: has no properties in common with type 'IntrinsicAttributes' */}
                  <Component {...p} />
                </Suspense>
              )}
            />
          );
        })}
      </Switch>
    </>
  );
};
